import { Channel } from "pusher-js";
import { create } from "zustand";
import { usePusherAuthenticationStore } from "./usePusherAuthenticationStore";

type SuccessEventData = {
  successPoints?: number;
  giftName?: string;
};

type FailureEventData = {
  code: string;
  message: string;
};

type EventState = {
  channel: Channel | null;
  successEventData: SuccessEventData | null;
  failureEventData: FailureEventData | null;
};

type EventAction = {
  subscribe: () => void;
  unsubscribe: () => void;
  handleSuccessEvent: (data: SuccessEventData) => void;
  handleFailureEvent: (data: FailureEventData) => void;
};

export const useQRLoyaltyPusherEventsStore = create<EventState & EventAction>(
  (set, getState) => ({
    channel: null,
    successEventData: null,
    failureEventData: null,
    subscribe: () => {
      const { pusher } = usePusherAuthenticationStore();
      if (!pusher) return;

      const channel = pusher.subscribe("store-pusher-authentication");

      channel.bind(
        "receive_qr_loyalty_points_success",
        (data: SuccessEventData) => {
          set({ successEventData: data });
        }
      );

      channel.bind(
        "receive_qr_loyalty_points_failure",
        (data: FailureEventData) => {
          set({ failureEventData: data });
        }
      );
      set({ channel });
    },
    unsubscribe: () => {
      const { channel } = getState();
      if (channel) {
        try {
          channel.unbind_all();
          channel.unsubscribe();
          set({
            channel: null,
            successEventData: null,
            failureEventData: null,
          });
          return true;
        } catch (error) {
          return false;
        }
      }
    },
    handleSuccessEvent: (data: SuccessEventData) => {
      set({ successEventData: data });
    },
    handleFailureEvent: (data: FailureEventData) => {
      set({ failureEventData: data });
    },
  })
);
