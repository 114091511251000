import { useState, useRef, useEffect } from "react";
import { ArrowIcon } from "@/assets/images";
import { SelectItem } from "@/types";
import strings from "@/localization/strings.json";
import { useTranslation } from "react-i18next";
import { isRTL } from "@/utils";

type DropdownSelectProps<T> = {
  options: SelectItem<T>[];
  onSelect: (option: SelectItem<T>) => void;
  initialValue: SelectItem<T>;
};

function DropdownSelect<T extends string | number | boolean>({
  options,
  onSelect,
  initialValue,
}: DropdownSelectProps<T>) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<SelectItem<T>>(initialValue);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (option: SelectItem<T>) => {
    onSelect(option);
    setValue(option);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="h-14 bg-[#F2F2F7] rounded-[4px] py-2 px-4 inline-flex items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-[28px] mr-2 font-poppins rtl:font-bahij text-gray-700 font-semibold" dir={isRTL ? "rtl" : "ltr"}>
          {value?.label ?? t(strings.select)}
        </span>
        <img src={ArrowIcon} />
      </button>
      {isOpen && (
        <div className="absolute mt-2 min-w-36 rounded-md shadow-lg bg-white">
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options.map((option, index) => (
                <a
                  key={index}
                  href="#"
                  dir={isRTL ? "rtl" : "ltr"}
                  className="block px-4 py-2 font-poppins rtl:font-bahij font-semibold text-[20px] text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                  onClick={() => handleSelect(option)}
                >
                  {option.label} {option.value.toString()}
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownSelect;
