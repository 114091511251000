import { memo, useEffect, useMemo, useState } from "react";
import { CountryCode, PhoneNumberProps, SelectItem } from "@/types";
import { useTranslation } from "react-i18next";
import strings from "@/localization/strings.json";
import DropdownSelect from "./DropdownSelect";
import { isRTL, validatePhoneNumber } from "@/utils";

function PhoneNumber({ countryCodes, setState, state }: PhoneNumberProps) {
  const { t } = useTranslation();
  const [blurred, setBlurred] = useState(false);
  const options: SelectItem<string>[] = useMemo(
    () =>
      countryCodes.map((countryCode: CountryCode) => ({
        value: countryCode.code,
        label: countryCode.flag,
      })),
    [countryCodes]
  );

  const initialValue = useMemo(
    () => options.find((option) => option.value === state.countryCode),
    [state.countryCode]
  );

  useEffect(() => {
    if (!state.phoneNumber && !state.isValid && !blurred) {
      setState({ ...state, isValid: true });
    }
  }, [state, blurred]);

  const handleCountryCodeChange = (option: SelectItem<string>) => {
    const isValid = validatePhoneNumber(state.phoneNumber, option.value);
    setState({
      ...state,
      countryCode: option.value,
      isValid: state.phoneNumber.length ? isValid : state.isValid,
    });
  };

  const handlePhoneNumberBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const isValid = validatePhoneNumber(event.target.value, state.countryCode);
    setBlurred(true);
    setState({ ...state, isValid });
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isValid = validatePhoneNumber(event.target.value, state.countryCode);
    setState({
      ...state,
      phoneNumber: event.target.value,
      isValid,
    });
  };
  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full flex flex-row items-center max-h-14 space-x-2">
        <DropdownSelect
          initialValue={initialValue as SelectItem<string>}
          options={options}
          onSelect={handleCountryCodeChange}
        />
        <div className="w-full h-14 flex flex-row items-center  px-3 bg-[#F2F2F7] rounded-[4px]">
          <span
            className="pr-2 text-[#1C1C1E] text-[17px] font-poppins rtl:font-bahij font-normal"
            dir={isRTL ? "rtl" : "ltr"}
          >
            {state.countryCode}
          </span>
          <input
            type="tel"
            onBlur={handlePhoneNumberBlur}
            placeholder={t(strings.phoneNumberInputPlaceholder)}
            className="w-full bg-transparent outline-none  text-[#1C1C1E] font-poppins rtl:font-bahij text-[17px] font-normal"
            value={state.phoneNumber}
            onChange={handlePhoneNumberChange}
          />
        </div>
      </div>

      {!state.isValid && (
        <span
          dir={isRTL ? "rtl" : "ltr"}
          className="text-red-500 w-full font-poppins rtl:font-bahij mt-2 rtl:text-right"
        >
          {t(strings.pleaseEnterAValidPhoneNumber)}
        </span>
      )}
    </div>
  );
}

export default memo(PhoneNumber);
