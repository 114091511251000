import { QR_LOYALTY_INFO_API_URL } from "../endpoints";

export const getQRLoyaltyInfo = async (params: { [key: string]: any }) => {
  const response = await fetch(
    `${import.meta.env.REACT_APP_CORE_ENDPOINTS + QR_LOYALTY_INFO_API_URL}?${new URLSearchParams(params)}`
  );
  if (!response.ok) {
    throw response;
  }
  const data = await response.json();
  return { data, ...response };
};
