import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationAR from "./ar.json";
import translationEN from "./en.json";
import { ValueOf } from "@/types";
const resources = {
  ar: {
    translation: translationAR,
  },
  en: {
    translation: translationEN,
  },
};

export type ValueOfTransaltion = ValueOf<typeof translationEN>
i18n.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: "ar",
  lng: "ar",
  resources,
});

export default i18n;
