import { StateStorage } from "zustand/middleware";

export const sessionStorageUtil: StateStorage = {
  getItem: (key): string | null => {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },
  setItem: (key, newValue): void => {
    sessionStorage.setItem(key, JSON.stringify(newValue));
  },
  removeItem: (key): void => {
    sessionStorage.removeItem(key);
  },
};
