export const validatePhoneNumber = (phoneNumber: string, countryCode: string) => {
  let isValid = false;
  switch (countryCode) {
    case "+966":
      const saudiPattern =
        /^(009665|9665|\+9665|05|5)(?!79)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
      isValid = saudiPattern.test(phoneNumber);
      break;
    case "+2":
      const egyptPattern = /^01[0125][0-9]{8}$/;
      isValid = egyptPattern.test(phoneNumber);
      break;
    default:
      break;
  }
  return isValid;
};
