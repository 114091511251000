import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { PhoneNumberState } from "@/types";
import { sessionStorageUtil } from "@/utils";

type UserInfoState = {
  userInfo: PhoneNumberState;
};

type UserInfoAction = {
  setPhoneNumber: (value: PhoneNumberState) => void;
};

const userInfoStorageOptions = {
  name: "userInfo",
  storage: createJSONStorage<UserInfoState & UserInfoAction>(
    () => sessionStorageUtil
  ),
};

export const useUserStore = create(
  persist<UserInfoState & UserInfoAction>(
    (set) => ({
      userInfo: {
        countryCode: "+966",
        phoneNumber: "",
        isValid: true,
      },
      setPhoneNumber: (value) => set({ userInfo: { ...value } }),
    }),
    userInfoStorageOptions
  )
);
