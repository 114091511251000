import { create } from "zustand";
import { ContactInfo } from "@/types";
import { getQRLoyaltyInfo } from "@/api";
import { createJSONStorage, persist } from "zustand/middleware";
import { sessionStorageUtil } from "@/utils";

type QRLoyaltyInfoState<T> = {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  data: T;
  error: Error | null;
};

type QRLoyaltyInfoAction = {
  fetchQRLoyaltyInfo: (payload: ContactInfo) => Promise<void>;
  resetQRLoyaltyInfo: () => void;
};

const qrLoyaltyInfoStorageOptions = {
  name: "qrLoyaltyInfo",
  storage: createJSONStorage<QRLoyaltyInfoState<any> & QRLoyaltyInfoAction>(
    () => sessionStorageUtil
  ),
};
export const useQRLoyaltyInfoStore = create(
  persist<QRLoyaltyInfoState<any> & QRLoyaltyInfoAction>(
    (set) => ({
      data: {},
      error: null,
      isLoading: false,
      isSuccess: false,
      isError: false,
      async fetchQRLoyaltyInfo(payload) {
        try {
          set({ isLoading: true });
          const { data } = await getQRLoyaltyInfo(payload);
          set({ data, isLoading: false, isSuccess: true });
        } catch (error) {
          set({ error: error as Error, isLoading: false, isError: true });
        }
      },
      resetQRLoyaltyInfo() {
        set({ isSuccess: false, isLoading: false });
      },
    }),
    qrLoyaltyInfoStorageOptions
  )
);
