import { StepItem } from "@/types";
import { mapById, sessionStorageUtil } from "@/utils";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type StepperStoreState = {
  steps: StepItem[];
  stepsById: { [x: string]: StepItem };
};

type StepperStoreAction = {
  updateStepperState: (path: string) => void;
};

const initialStepperState = [
  {
    id: "/",
    active: true,
  },
  {
    id: "/scan-for-points",
    active: false,
  },
];

const stepperStoreStorageOptions = {
  name: "steps",
  storage: createJSONStorage<StepperStoreState & StepperStoreAction>(
    () => sessionStorageUtil
  ),
};

export const useStepperStore = create(
  persist<StepperStoreState & StepperStoreAction>(
    (set) => ({
      stepsById: mapById(initialStepperState),
      steps: initialStepperState,
      updateStepperState: (path) =>
        set(({ steps }) => {
          // If payload equals "/", reset all steps
          if (!path.includes("/scan-for-points")) {
            return {
              steps: initialStepperState,
            };
          }
          // Find the current active step
          const currentActiveStep = steps.find((step) => step.active);

          // Update the steps array
          const updatedSteps = steps.map((step) => {
            // If the step's id matches the payload, set active to true
            // Otherwise, if the step was previously active, keep it active
            if (
              step.id === path ||
              (currentActiveStep && step.id === currentActiveStep.id)
            ) {
              return { ...step, active: true };
            } else {
              return step;
            }
          });

          return {
            steps: updatedSteps,
            stepsById: mapById(updatedSteps),
          };
        }),
    }),
    stepperStoreStorageOptions
  )
);
