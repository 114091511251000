export type CountryCode = {
  code: string;
  flag: string;
};

export type PhoneNumberState = {
  countryCode: string;
  phoneNumber: string;
  isValid: boolean;
};

export type PhoneNumberProps = {
  countryCodes: CountryCode[];
  state: PhoneNumberState;
  setState: (value: PhoneNumberState) => void;
};

export enum DeviceType {
  ANDROID = "android",
  IPHONE = "iphone",
}

export type StepItem = { id: string; active: boolean };

export type ValueOf<T> = T[keyof T];

export type Store = {
  id: string;
  store_image: string;
  background_color: string;
  name: string;
  country_code: string;
};

export type ContactInfo = {
  phone_number: string;
  country_code: string;
};

export type SelectItem<T> = {
  value: T;
  label: string;
};
