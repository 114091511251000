import {
  Routes,
  Route,
  createBrowserRouter,
  useParams,
  useNavigate,
} from "react-router-dom";
import { lazy, useEffect } from "react";
import { useStoreInfo } from "@/store";
import { LoadingIndicator } from "@/components";

const Home = lazy(() => import("./Home"));
const ScanForPoints = lazy(() => import("./ScanForPoints"));
const GetAppRedeemReward = lazy(() => import("./GetAppRedeemReward"));
const GetKoinzApp = lazy(() => import("./GetKoinzApp"));

function Root() {
  const {
    fetchStoreInfo,
    store: { id: currentStoreId },
    statusCode,
    loading,
    resetStoreInfo,
  } = useStoreInfo();
  const { storeId } = useParams<{ storeId: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    if (storeId && storeId !== currentStoreId) {
      if (statusCode && (statusCode === 404 || statusCode === 500)) {
        navigate("/get-koinz-app", { replace: true });
        resetStoreInfo();
      } else if (!statusCode || statusCode === 200) {
        fetchStoreInfo(storeId);
      }
    }
  }, [storeId, currentStoreId, statusCode]);

  return (
    <Routes>
      <Route
        index
        Component={() => {
          if (!loading && statusCode === 200) {
            return <Home />;
          }
          return <LoadingIndicator />;
        }}
      />
      <Route path="scan-for-points" element={<ScanForPoints />} />
      <Route path="get-app-redeem-reward" element={<GetAppRedeemReward />} />
      <Route path="*" element={<GetKoinzApp />} />
    </Routes>
  );
}

export const router = createBrowserRouter([
  {
    path: "/:storeId/*",
    element: <Root />,
  },
  {
    path: "/get-koinz-app",
    element: <GetKoinzApp />,
  },
  {
    path: "*",
    element: <GetKoinzApp />,
  },
]);
