import { initializeApp } from "firebase/app";
import {
  logEvent,
  getAnalytics,
  CustomEventName,
  setAnalyticsCollectionEnabled,
} from "firebase/analytics";
export const initFirebaseApp = () => {
  const firebaseConfig = {
    apiKey: import.meta.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: import.meta.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: import.meta.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: import.meta.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  initializeApp(firebaseConfig);
  const analytics = getAnalytics();
  setAnalyticsCollectionEnabled(analytics, true);
};

export type EventPayload = {
  phone_number: string;
  store_id: string;
  store_name: string;
  timestamp: number;
  os: string;
  is_gift: boolean;
  user_id?: string;
};
export type EventParams = {
  Continue_Clicked: Omit<EventPayload, "is_gift" | "os">;
  QR_Code_Screen_Seen: Omit<EventPayload, "is_gift">;
  Added_To_Apple_Wallet: Omit<EventPayload, "is_gift" | "os">;
  App_Download_Prompt_Seen: Partial<EventPayload>;
  App_Download_Prompt_Clicked: Partial<EventPayload>;
};

export const firebaseLogEvent = <T extends keyof EventParams>(
  event_name: T,
  params: EventParams[T]
) => {
  logEvent(getAnalytics(), event_name as CustomEventName<T>, params);
};
