import "./App.css";
import { I18nextProvider } from "react-i18next";
import i18n from "@/localization/i18n";
import { Suspense, useEffect } from "react";
import { isRTL } from "./utils";
import { useClientIdStore } from "./store";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/__root";
import { LoadingIndicator } from "./components";

function App() {
  const { clientId, generateClientId } = useClientIdStore();
  useEffect(() => {
    i18n.changeLanguage(isRTL ? "ar" : "en");
  }, [isRTL]);

  useEffect(() => {
    if (!clientId) {
      generateClientId();
    }
  }, [clientId, generateClientId]);

  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<LoadingIndicator />}>
        <RouterProvider router={router} />
      </Suspense>
    </I18nextProvider>
  );
}

export default App;
