import { QR_LOYALTY_STORE_API_URL } from "../endpoints";

export const getStoreInfo = async (store_id: string) => {
  const response = await fetch(
    import.meta.env.REACT_APP_CORE_ENDPOINTS +
      QR_LOYALTY_STORE_API_URL +
      store_id
  );
  if (!response.ok) {
    throw response;
  }
  const data = await response.json();
  return { data, status: response.status };
};
