import { create } from "zustand";
import Pusher from "pusher-js";
import { PUSHER_AUTHENTICATION_API_URL } from "@/api";

type AuthState = {
  pusher: Pusher | null;
};

type AuthAction = {
  authenticate: () => Promise<void>;
};
export const usePusherAuthenticationStore = create<AuthState & AuthAction>((set) => ({
  pusher: null,
  authenticate: async () => {
    const authURL =
      import.meta.env.REACT_APP_CORE_ENDPOINTS + PUSHER_AUTHENTICATION_API_URL;
    const pusher = new Pusher(import.meta.env.REACT_APP_PUSHER_API_KEY, {
      cluster: "eu",
      authEndpoint: authURL,
    });

    await new Promise((resolve) => {
      pusher.connection.bind("connected", resolve);
    });

    const socketId = pusher.connection.socket_id;

    await fetch(authURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ socketId }),
    });

    set({ pusher });
  },
}));
