// clientIdStore.ts
import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";
import { createJSONStorage, persist } from "zustand/middleware";
import { sessionStorageUtil } from "@/utils";

type ClientIdState = {
  clientId: string | null;
};

type ClientIdAction = {
  generateClientId: () => void;
};

const clientIdStorageOptions = {
  name: "clientId",
  storage: createJSONStorage<ClientIdState & ClientIdAction>(
    () => sessionStorageUtil
  ),
};
export const useClientIdStore = create(
  persist<ClientIdState & ClientIdAction>(
    (set) => ({
      clientId: null,
      generateClientId() {
        const newClientId = uuidv4();
        set({ clientId: newClientId });
      },
    }),
    clientIdStorageOptions
  )
);
