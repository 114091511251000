import { useStoreInfo } from "@/store";

function LoadingIndicator() {
  const {
    store: { background_color },
  } = useStoreInfo();
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <span
        style={{ backgroundColor: background_color ?? "" }}
        className={`animate-ping inline-flex h-20 w-20 rounded-full bg-orange-400 opacity-75`}
      />
    </div>
  );
}

export default LoadingIndicator;
