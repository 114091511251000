import { create } from "zustand";
import { ContactInfo } from "@/types";
import { getQRLoyaltyUpdates } from "@/api";
import { createJSONStorage, persist } from "zustand/middleware";
import { sessionStorageUtil } from "@/utils";

type QRLoyaltyUpdatesState = {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  statusCode: number | null;
  data:
    | {
        points?: number;
        gift_name?: string;
        user_id?: string;
      }
    | undefined;
  error: Error | null;
};

type QRLoyaltyUpdatesAction = {
  resetQRLoyaltyUpdates: () => void;
  fetchQRLoyaltyUpdates: (
    payload: ContactInfo & { store_id: string; client_id: string }
  ) => void;
};

const qrLoyaltyUpdatesStorageOptions = {
  name: "qrLoyaltyUpdates",
  storage: createJSONStorage<QRLoyaltyUpdatesState & QRLoyaltyUpdatesAction>(
    () => sessionStorageUtil
  ),
};

export const useQRLoyaltyUpdatesStore = create(
  persist<QRLoyaltyUpdatesState & QRLoyaltyUpdatesAction>(
    (set) => ({
      data: undefined,
      error: null,
      isLoading: false,
      isSuccess: false,
      isError: false,
      statusCode: null,
      resetQRLoyaltyUpdates() {
        set({
          data: undefined,
          error: null,
          isLoading: false,
          isSuccess: false,
          isError: false,
          statusCode: null,
        });
      },
      async fetchQRLoyaltyUpdates(payload) {
        try {
          set({ isLoading: true });
          const { status, data } = await getQRLoyaltyUpdates(payload);
          set({ data, isLoading: false, isSuccess: true, statusCode: status });
        } catch (error: any) {
          set({
            error,
            isLoading: false,
            isError: true,
            statusCode: error.status,
            isSuccess: false,
          });
        }
      },
    }),
    qrLoyaltyUpdatesStorageOptions
  )
);
