import { Store } from "@/types";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { sessionStorageUtil } from "@/utils";
import { getStoreInfo } from "@/api";

type StoreInfoState = {
  error: Error | null;
  loading: boolean;
  store: Store;
  statusCode: number | null;
};

type StoreAction = {
  fetchStoreInfo: (storeId: string) => void;
  resetStoreInfo: () => void;
};

const storeStorageOptions = {
  name: "store",
  storage: createJSONStorage<StoreInfoState & StoreAction>(
    () => sessionStorageUtil
  ),
};
export const useStoreInfo = create(
  persist<StoreInfoState & StoreAction>(
    (set) => ({
      error: null,
      statusCode: null,
      store: {
        background_color: "",
        country_code: "",
        id: "",
        name: "",
        store_image: "",
      },
      loading: false,
      resetStoreInfo() {
        set({
          error: null,
          statusCode: null,
          store: {
            background_color: "",
            country_code: "",
            id: "",
            name: "",
            store_image: "",
          },
          loading: false,
        });
      },
      fetchStoreInfo: async (store_id) => {
        try {
          set({ loading: true });
          const { data: store, status } = await getStoreInfo(store_id);
          set({
            store: {
              ...store,
              store_image:
                import.meta.env.REACT_APP_CORE_ENDPOINTS + store.store_image,
            },
            loading: false,
            statusCode: status
          });
        } catch (error: any) {
          set({
            error,
            loading: false,
            statusCode: error.status,
            store: {
              background_color: "",
              country_code: "",
              id: "",
              name: "",
              store_image: "",
            },
          });
        }
      },
    }),
    storeStorageOptions
  )
);
